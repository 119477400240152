import React from 'react'

export default ({team})=> (
    <section className="team about">
        <div className="section">
            <div className="container">
                <div className="columns is-multiline">
                    {
                        team.map(({node},index) => {
                            return(
                                index%2==0 ? (
                                    <div className="section" key={index}>
                                        <div className="container box">
                                            <div className="columns">
                                                <div className="column">
                                                    <figure className="image">
                                                        <img className="is-rounded" src={`${node.image.file.url}`}/>
                                                    </figure>
                                                </div>
                                                <div className="column is-8">
                                                    <h2 className="title is-size-2 is-capitalized">
                                                        {node.name}
                                                    </h2>
                                                    <hr className="title-underline"/>
                                                    <h3 className="subtitle is-size-4 is-capitalized">{node.title}</h3>
                                                    <div className="is-size-4"
                                                        dangerouslySetInnerHTML={{
                                                            __html: node.shortBio.childMarkdownRemark.html 
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="section" key={index}>
                                        <div className="container box">    
                                            <div className="columns">
                                                <div className="column is-4">
                                                    <figure className="image">
                                                        <img className="is-rounded" src={`${node.image.file.url}`}/>
                                                    </figure>
                                                </div>
                                                <div className="column is-8">
                                                    <h2 className="title is-size-2 is-capitalized">
                                                        {node.name}
                                                    </h2>
                                                    <hr className="title-underline"/>
                                                    <h3 className="subtitle is-size-4 is-capitalized">{node.title}</h3>
                                                    <div className="is-size-4"
                                                        dangerouslySetInnerHTML={{
                                                            __html: node.shortBio.childMarkdownRemark.html 
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </section>    
)