import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Contact from '../components/contact'
import Team from '../components/team'
import SiteMeta from '../components/meta-data'


class AboutPage extends React.Component {
  render() {
    const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
    const data = {
      hero: 'hero-about',
      size: 'is-medium',
      isHidden: true,
      text: 'who we are?',
      servicesNav
    }

    const metaData = get(this.props, 'data.site.siteMetadata.aboutPage')
    const team = get(this, 'props.data.allContentfulPerson.edges')
    return (
      <Layout location={this.props.location} data={data} >
        <SiteMeta metaData={metaData}/>
        <Team team={team}/>
        <Contact/>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        aboutPage {
          title
          description
          imageUrl
          siteUrl
        }
      }
    }
    allContentfulPerson(filter: {node_locale: {eq: "en-GB"}}, sort: {fields: order}) {
      edges {
        node {
          name
          title
          order
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          slug
        }
      }
    } 
  }
`


